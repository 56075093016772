import en from "@/locales/en.json";
import fr from "@/locales/fr.json";

export const loadFallbacksTranslations = async () => {
  try {
    const nuxtApp = useNuxtApp();
    const runtimeConfig = useRuntimeConfig();
    const locale = nuxtApp.$i18n.locale;
    const translationsStore = useTranslationsStore();
    const { setTranslations } = translationsStore;

    // Load the appropriate locale file
    let translations = null;
    if (locale.value === "en") {
      translations = en;
    } else if (locale.value === "fr") {
      translations = fr;
    }

    // Check if locale is not supported
    if (!translations) {
      throw new Error(`Locale ${locale.value} not supported`);
    }

    // Remove unecessary data from json file
    if (runtimeConfig.public.DEV_MODE) {
      const filteredTranslations = Object.fromEntries(
        Object.entries(translations).map(([key, value]) => [
          key,
          // @ts-ignore
          value.body.static,
        ]),
      );
      setTranslations(filteredTranslations);
    } else {
      const filteredTranslations = Object.fromEntries(
        // @ts-ignore
        Object.entries(translations).map(([key, value]) => [key, value.b.s]),
      );
      setTranslations(filteredTranslations);
    }
  } catch (error) {
    console.error("loadFallbacksTranslations", error);
  }
};
