<script setup>
const error = useError();

console.error(error.value);

const handleError = () => {
  clearError({
    redirect: "/",
  });
};
</script>

<template>
  <NuxtLayout>
    <!-- 404 page -->
    <main
      v-if="error?.statusCode === 404"
      class="flex min-h-full flex-col items-center justify-center gap-2 p-2"
    >
      <h1>
        <strong>404</strong>
      </h1>
      <p>Sorry, that page doesn't exist.</p>
    </main>

    <!-- 503 page -->
    <main
      v-else-if="error?.statusCode === 503"
      class="flex min-h-full flex-col items-center justify-center gap-2 p-2"
    >
      <h1>
        <strong>We'll be back soon!</strong>
      </h1>
      <p>Website under maintenance.</p>
    </main>

    <!-- Default error page -->
    <main
      v-else-if="error"
      class="flex min-h-full flex-col items-center justify-center gap-5 p-2"
    >
      <h1 class="text-2xl">
        <strong v-if="error?.statusCode"
          >ERROR - {{ error?.statusCode }}</strong
        >
        <strong v-else>ERROR</strong>
      </h1>

      <!-- Error message -->
      <pre
        v-if="error?.message"
        class="flex w-full items-center justify-center text-xl"
      >
          <strong>{{ error?.message }}</strong>
        </pre>

      <!-- Error stack trace -->
      <pre
        v-if="error?.stack"
        v-html="error?.stack"
        class="mx-8 max-w-full rounded-lg border border-gray-300 px-5 py-2.5"
      ></pre>

      <!-- Error resolver -->
      <a
        class="dark:border-white-600 cursor-pointer rounded-lg border border-gray-300 px-5 py-2.5 text-center text-sm font-bold text-gray-900 transition-colors hover:bg-gray-900 hover:text-white dark:text-white dark:hover:bg-white dark:hover:text-black"
        @click="handleError"
      >
        HOME
      </a>
    </main>
  </NuxtLayout>
</template>
