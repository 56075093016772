import { default as _91pagePath_93u2Cvek8BMmMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/[pagePath].vue?macro=true";
import { default as agenceVUspRWXjphMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/agence.vue?macro=true";
import { default as avant_45apresk8RH8zYL9mMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/avant-apres.vue?macro=true";
import { default as index784P6pIpVeMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/index.vue?macro=true";
import { default as previewxG4kib9TDHMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/preview.vue?macro=true";
import { default as _91slug_93pTBEQUMRMiMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/projet/[slug].vue?macro=true";
import { default as testNWivcs0EmWMeta } from "/Users/ulysse/Dev/Agence-Prost/nuxt/pages/test.vue?macro=true";
export default [
  {
    name: "pagePath___fr",
    path: "/:pagePath()",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/[pagePath].vue").then(m => m.default || m)
  },
  {
    name: "pagePath___en",
    path: "/en/:pagePath()",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/[pagePath].vue").then(m => m.default || m)
  },
  {
    name: "agence___fr",
    path: "/agence",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/agence.vue").then(m => m.default || m)
  },
  {
    name: "agence___en",
    path: "/en/agence",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/agence.vue").then(m => m.default || m)
  },
  {
    name: "avant-apres___fr",
    path: "/avant-apres",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/avant-apres.vue").then(m => m.default || m)
  },
  {
    name: "avant-apres___en",
    path: "/en/avant-apres",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/avant-apres.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "preview___fr",
    path: "/preview",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: "preview___en",
    path: "/en/preview",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: "projet-slug___fr",
    path: "/projet/:slug()",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/projet/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projet-slug___en",
    path: "/en/projet/:slug()",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/projet/[slug].vue").then(m => m.default || m)
  },
  {
    name: "test___fr",
    path: "/test",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___en",
    path: "/en/test",
    component: () => import("/Users/ulysse/Dev/Agence-Prost/nuxt/pages/test.vue").then(m => m.default || m)
  }
]