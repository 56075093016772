// Fallback/default translations in @locales/current_locale.json files loaded with @utils/loadFallbacksTranslations.ts

import { defineStore } from "pinia";

export const useTranslationsStore = defineStore("translationsStore", () => {
  const allTranslations = ref({
    site_title: "Nuxt x Strapi",
    site_description: "Description",
    nothing_found: "No results...",
    error_message: "Oups, an error happened...",
    error_404: "Oups, page not found !",
    error_503: "Website under maintenance, sorry we'll be back very soon !",
    test: "Test - EN",
    projects: "Projects",
  });

  const setTranslations = (translations: any) => {
    allTranslations.value = { ...allTranslations.value, ...translations };
  };

  return { setTranslations, allTranslations };
});
