<script setup lang="ts">
// Types
import type { Query, ComponentSocialsSeo } from "@/types/Strapi";

// i18n / Language
const locale = ref(useI18n().locale);

// GraphQL
import { GET_META_AND_TRANSLATIONS } from "@/gql/MetaAndTranslations";
const { client } = useApolloClient();

// i18n Head
// Docs : https://v8.i18n.nuxtjs.org/guide/seo
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});

// Get hardcoded translations
await loadFallbacksTranslations();

// Query meta informations and translations from Strapi
const handleQuery = (data: Query | null, error: any) => {
  try {
    // Graphql error + nothing found
    if (error || !data) {
      console.error(error);
    } else {
      const strapiMeta = data?.websiteInformation?.data?.attributes
        ?.SEO as ComponentSocialsSeo;
      useHead({
        titleTemplate: (titleChunk) => {
          if (strapiMeta?.title == titleChunk) return titleChunk;
          return titleChunk
            ? `${titleChunk} - ${strapiMeta?.title}`
            : strapiMeta?.title;
        },
      });
      setMetaAndTranslations(data, error);
    }
  } catch (error) {
    console.error(error);
  }
};

// Fetch on setup
const { data, error } = await useAsyncQuery<Query>(GET_META_AND_TRANSLATIONS, {
  locale: locale.value,
});
handleQuery(data.value, error.value);

// Watch for locale changes so meta tags and translations update when changing language
watch(
  () => locale.value,
  async () => {
    await loadFallbacksTranslations();
    const { data, error } = await client.query({
      query: GET_META_AND_TRANSLATIONS,
      variables: {
        locale: locale.value,
      },
    });
    handleQuery(data, error);
  },
);
</script>

<template>
  <div>
    <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
      <Head>
        <template v-for="link in head.link" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
        <Meta
          name="ahrefs-site-verification"
          content="b4992c116d5d9c7e5f2b8d147428875b94ed6c4b5fb81e1a9351952aecf8174c"
        />
      </Head>
      <Body>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </Body>
    </Html>
  </div>
</template>

<style>
/* Transitions */
.custom-enter-from,
.custom-leave-to {
  opacity: 0;
}
</style>
