import revive_payload_client_4sVQNw7RlN from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/ulysse/Dev/Agence-Prost/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import slideovers_LDumGYo2KH from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_eTVJQYlCmx from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import umami_5KgQP7X3nR from "/Users/ulysse/Dev/Agence-Prost/nuxt/node_modules/nuxt-umami/plugins/umami.ts";
import Vue3Lottie_client_bMZiOS6AB0 from "/Users/ulysse/Dev/Agence-Prost/nuxt/plugins/Vue3Lottie.client.ts";
import apollo_1ILFkzpYSr from "/Users/ulysse/Dev/Agence-Prost/nuxt/plugins/apollo.ts";
import sentry_3AyO8nEfhE from "/Users/ulysse/Dev/Agence-Prost/nuxt/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  i18n_yfWm7jX06p,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  umami_5KgQP7X3nR,
  Vue3Lottie_client_bMZiOS6AB0,
  apollo_1ILFkzpYSr,
  sentry_3AyO8nEfhE
]