export default defineAppConfig({
  ui: {
    primary: "default",
    gray: "zinc",
    icons: {
      dynamic: true,
    },
    notification: {
      wrapper: "absolute m-auto top-32 left-0 right-0 max-w-xl w-full",
      container: "",
      icon: { base: "self-center w-8 h-8" },
    },
    dropdown: {
      wrapper: "dropdown-wrapper bg-transparent",
      container: "dropdown-container bg-transparent	",
      trigger: "dropdown-trigger",
      background:
        "bg-transparent shadow-none border-none shadow-transparent ring-transparent	",
      item: {
        base: "dropdown-item btn w-fit ml-auto",
      },
    },
    button: {
      color: {
        white: {
          solid: "dropdown-btn btn",
        },
      },
    },
  },
  umami: {
    version: 2,
    ignoreLocalhost: true,
  },
});
